import styles from './styles.module.css';
import React from 'react';
import { Helmet } from 'react-helmet';
import { MainImage } from 'src/components/mainImage';
import { MultiCarousel } from 'src/components/multiCarousel';
import { ReadMore } from 'src/components/readMore';
import { SectionContainer, SectionContainerTitle } from 'src/components/sectionContainer';
import {
	ContentfulWelfarePage,
	ContentfulProjectResource,
	ContentfulPublicationResource,
	ContentfulReportResource,
	ContentfulArticleResource,
	Maybe,
	ContentfulCollapsiblePanelSection,
} from 'src/graphql-types';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import {
	SectionWithSearchResourceTemplate,
	mapResources,
} from 'src/templates/sectionWithSearchResourceTemplate/sectionWithSearchTemplate';
import {
	defaultRichTextOptions,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import { LinkHelper } from 'src/utils/link-helper';
import {
	defaultWelfarePageSections,
	FilterContentTypeItems,
	RelevantPages,
	formatDate,
	getHrImageUrl,
	PrivateCollapsiblePanelSection,
} from 'src/utils/common';
import { curatedTitle } from 'src/utils/curated-title';
import { CollapsiblePanelCollection } from '../collapsiblePanel/collapsiblePanelCollection';

interface ModifiedContentfulWelfarePage
	extends Omit<ContentfulWelfarePage, 'overviewCollapsiblePanels'> {
	overviewCollapsiblePanels?: Maybe<
		ContentfulCollapsiblePanelSection | PrivateCollapsiblePanelSection
	>[];
}

interface PageContext {
	pageContext: {
		welfarePage: ModifiedContentfulWelfarePage;
		allContentfulProjectResource?: {
			nodes: ContentfulProjectResource[];
		};
		allContentfulPublicationResource?: {
			nodes: ContentfulPublicationResource[];
		};
		allContentfulReportResource?: {
			nodes: ContentfulReportResource[];
		};
		allContentfulArticleResources?: {
			nodes: ContentfulArticleResource[];
		};
	};
}

export const WelfarePageDetails = (props: PageContext) => {
	const { welfarePage: pageData } = props?.pageContext;
	const {
		allContentfulProjectResource,
		allContentfulPublicationResource,
		allContentfulReportResource,
		allContentfulArticleResources,
	} = props?.pageContext;

	if (!pageData) {
		console.error('No page data for this WelfarePage');
		return (
			<SectionContainer withBorderTop>
				<p className={styles.message}>This page is under maintenance. Please try again later</p>
			</SectionContainer>
		);
	}

	const {
		contentful_id,
		title,
		subtitle,
		titleBackgroundImage,
		topSectionContent,
		featuredProjects,
		featuredPublicationsAndReports,
		videos,
		overviewHeading,
		researchProjectsHeading,
		publicationsReportsHeading,
		videosHeading,
		overviewCollapsiblePanels,
	} = pageData;

	return (
		<DefaultLayoutTemplate>
			<Helmet>
				<title>{curatedTitle(title)}</title>
			</Helmet>
			<MainImage
				backgroundImageSrc={getHrImageUrl(titleBackgroundImage?.file?.url)}
				header={title}
				paragraph={subtitle}
			/>
			<SectionContainer isGrey>
				<SectionContainerTitle
					text={overviewHeading || defaultWelfarePageSections.overview}
					id={LinkHelper.parseInternalLink(overviewHeading || defaultWelfarePageSections.overview)}
				/>
				{topSectionContent && (
					<div className={styles.overviewContentContainer}>
						{getRichText(topSectionContent, defaultRichTextOptions)}
					</div>
				)}
				<CollapsiblePanelCollection
					panelsCollection={overviewCollapsiblePanels}
					parentId={LinkHelper.parseInternalLink(
						overviewHeading || defaultWelfarePageSections.overview
					)}
				/>
			</SectionContainer>
			<SectionWithSearchResourceTemplate
				featuredResources={mapResources(featuredProjects)}
				publicRelevantResources={mapResources(allContentfulProjectResource?.nodes)}
				page={RelevantPages.Welfare}
				resourceTypes={[FilterContentTypeItems.Project]}
				title={researchProjectsHeading || defaultWelfarePageSections.projects}
				titleId={LinkHelper.parseInternalLink(
					researchProjectsHeading || defaultWelfarePageSections.projects
				)}
			/>
			<SectionWithSearchResourceTemplate
				featuredResources={mapResources(featuredPublicationsAndReports)}
				publicRelevantResources={mapResources([
					...(allContentfulPublicationResource?.nodes || []),
					...(allContentfulReportResource?.nodes || []),
					...(allContentfulArticleResources?.nodes || []),
				])}
				page={RelevantPages.Welfare}
				resourceTypes={[
					FilterContentTypeItems.Publication,
					FilterContentTypeItems.Report,
					FilterContentTypeItems.Article,
				]}
				title={publicationsReportsHeading || defaultWelfarePageSections.publications}
				titleId={LinkHelper.parseInternalLink(
					publicationsReportsHeading || defaultWelfarePageSections.publications
				)}
			/>
			{videos?.length && (
				<SectionContainer isGrey>
					<SectionContainerTitle
						text={videosHeading || defaultWelfarePageSections.videos}
						withSeparator
						id={LinkHelper.parseInternalLink(videosHeading || defaultWelfarePageSections.videos)}
					/>
					<MultiCarousel
						itemsShown={{
							largeScreen: 2,
							mediumScreen: 2,
							smallScreen: 1,
						}}
					>
						{videos
							.filter((v) => v)
							.map((video) => {
								return (
									<ReadMore
										key={video!.contentful_id!}
										image={
											(video!.thumbnailLink?.thumbnailImage && {
												src: video!.thumbnailLink?.thumbnailImage?.fluid?.src,
												style: { height: '300px', width: 'auto' },
											}) ||
											undefined
										}
										title={{
											titleText: video!.thumbnailLink?.title || '',
										}}
										publishedDate={formatDate(video!.publicationDate)}
										linkSection={{
											linkTo: LinkHelper.linkTo(video!),
											withBorderTop: true,
											readMoreText: 'Watch Video',
										}}
									>
										{video!.thumbnailLink?.description}
									</ReadMore>
								);
							})}
					</MultiCarousel>
				</SectionContainer>
			)}
		</DefaultLayoutTemplate>
	);
};

export default class WelfarePage extends React.Component<PageContext> {
	render() {
		return <WelfarePageDetails {...this.props} />;
	}
}
